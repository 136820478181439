.main {
}

.body {
	background-color: #fffdf8;
	min-height: 100vh;
	/* width: 100vw; */
}

::-webkit-scrollbar {
	display: none;
}

.profilePic {
	width: 100%;
}

.topPresentation {
	border-bottom: #131313;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.root {
	display: flex;
	justify-content: center;
}

.content {
	padding-top: 64px;
	background-color: #fffdf8;
}

.listCard {
	justify-content: center;
	align-items: center;
	display: flex;
	padding-bottom: 16px;
}

.listItem {
	position: relative;
	width: 70vw;
	height: 70vw;
	color: #131313;
	overflow: hidden;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.listItem img {
	height: 80%;
	width: 100%;
	vertical-align: bottom;
	object-fit: cover;
}

.text {
	height: 20%;
}

.description {
}

.sidebar {
	width: 10vw;
	flex-shrink: 0;
}

.topbar {
	flex-grow: 1;
}

.toolbar {
	color: #131313;
	overflow: auto;
}

.topbarButton {
	transition: color 0.2s ease;
}

.topbarButton:hover {
	color: #15a0ee !important;
	cursor: pointer;
}

.topbarButton:active {
	text-decoration: underline;
}

.link {
	text-decoration: none;
}

.cardImg {
	display: block;

	width: 100%;
	height: 100%;
	transition: 0.3s;
}

.fadeable {
	opacity: 1;
	transition: 0.3s;
}

.fadeable:hover {
	opacity: 0.2;
}

.fade {
	-webkit-filter: opacity(30%);
	filter: opacity(30%);
}

.cardTextWraper {
	height: 100%;
	display: table;
	background-color: #fffdf8;
}

.cardText {
	display: table-cell;
	padding-left: 16px;
}

.cardTextRight {
	display: table-cell;
	vertical-align: middle;
	padding-right: 16px;
}

.ProfessionalInfoCard {
	width: 100%;
	height: 100%;
	color: #131313;
	background-color: #fffdf8;
}

.shadowed {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.hoverable {
	transition: transform 0s ease;
	cursor: pointer;
}

.hoverable:hover {
	transform: scale(1);
	z-index: 1;
	cursor: pointer;
}

.infoCardWraper {
	justify-content: center;
	align-items: center;
	display: flex;
}

.topTitle {
	justify-content: center;
	align-items: center;
	padding-bottom: 16px;
}

.infoText {
}

.leftPartOfProject {
	height: 100px;
	width: 45%;
	border: 1px solid red;
	float: left;
	overflow-y: scroll;
}

.breadText {
	white-space: pre-wrap;
}

#rightPartOfProject {
	height: 100px;
	width: 45%;
	margin-left: 50%;
	border: 1px solid blue;
	overflow-y: scroll;
}
